import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

// Import your root reducer(s)
import rootReducer from './reducers'; // Replace this with your root reducer

// Redux Persist configuration
const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
    reducer: persistedReducer,
    middleware: [logger] // Apply redux-logger as a middleware
});

// Create a persistor object
const persistor = persistStore(store);

export { store, persistor };
