import { combineReducers } from 'redux';
import auth from './auth/reducer';
import prescription from './prescription/reducer';
import disease from './disease/reducer';

const reducers = combineReducers({
    auth,
    prescription,
    disease
});
export default reducers;
