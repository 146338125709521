import dayjs from 'dayjs';
import { MEDICINE_ROUTE, PRESCRIPTION_TYPE } from '../../utils/constant';
import { PRESCRIPTION } from './actionType';
import { isoTime } from '../../utils/functions';

const initialState = {
    getProfile: {},
    chamber: {},
    calendar: new Date(),
    patientDisease: [],
    prescriptions: [],
    patientDetails: {},
    isRegistered: false,
    addPatient: {
        patient_name: '',
        country_code: '+880',
        phone_number: '',
        birth_date: dayjs(new Date()),
        gender: 'male'
    },
    diagnosis: '',
    testFindings: '',
    generalAdvice: '',
    planOfTreatment: '',
    differentialDiagnosis: '',
    testAdvice: [],
    testAdviceCustomText: '',
    testAdviceSelected: [],
    referDiagnosticCenter: '',
    nextFollowUp: {
        isFollowUp: true,
        day: 1,
        interval: 'month',
        isoTimeString: isoTime(1, 'month')
    },
    medicineList: [],
    rxMedicineList: [],
    healthMatrix: [],
    healthMatrixCustom: [],
    healthMatrixClone: [],
    selectedHealthMatrix: [],
    selectedHealthMatrixIds: [],
    medicineRoute: [],
    prescriptionSubscriptionPlan: false,
    designationList: [],
    specializationList: []
};

const prescription = (state = initialState, action) => {
    switch (action.type) {
        case PRESCRIPTION.CHAMBER:
            return {
                ...state,
                chamber: action.payload
            };
        case PRESCRIPTION.CALENDAR:
            return {
                ...state,
                calendar: action.payload
            };
        case PRESCRIPTION.GET_PROFILE:
            return {
                ...state,
                getProfile: action.payload
            };
        case PRESCRIPTION.PATIENT_DISEASE:
            return {
                ...state,
                patientDisease: action.payload
            };
        case PRESCRIPTION.CREATE:
            return {
                ...state,
                prescriptions: action.payload
            };
        case PRESCRIPTION.PATIENT_DETAILS:
            return {
                ...state,
                patientDetails: action.payload
            };
        case PRESCRIPTION.IS_REGISTERED_PATIENT:
            return {
                ...state,
                isRegistered: action.payload
            };
        case PRESCRIPTION.ADD_PATIENT:
            return {
                ...state,
                addPatient: action.payload
            };
        case PRESCRIPTION_TYPE.DIAGNOSIS:
            return {
                ...state,
                diagnosis: action.payload
            };
        case PRESCRIPTION_TYPE.DIFFERENTIAL_DIAGNOSIS:
            return {
                ...state,
                differentialDiagnosis: action.payload
            };
        case PRESCRIPTION_TYPE.TEST_FINDINGS:
            return {
                ...state,
                testFindings: action.payload
            };
        case PRESCRIPTION_TYPE.GENERAL_ADVICE:
            return {
                ...state,
                generalAdvice: action.payload
            };
        case PRESCRIPTION_TYPE.PLAN_OF_TREATMENT:
            return {
                ...state,
                planOfTreatment: action.payload
            };
        case PRESCRIPTION.TEST_ADVICE_LIST:
            return {
                ...state,
                testAdvice: action.payload
            };
        case PRESCRIPTION.TEST_ADVICE_CUSTOM_TEXT:
            return {
                ...state,
                testAdviceCustomText: action.payload
            };
        case PRESCRIPTION.REFER_DIAGNOSTIC_CENTER:
            return {
                ...state,
                referDiagnosticCenter: action.payload
            };
        case PRESCRIPTION.TEST_ADVICE_SELECTED_LIST:
            return {
                ...state,
                testAdviceSelected: action.payload
            };
        case PRESCRIPTION.NEXT_FOLLOW_UP:
            return {
                ...state,
                nextFollowUp: action.payload
            };
        case PRESCRIPTION.MEDICINE_LIST:
            return {
                ...state,
                medicineList: action.payload
            };
        case PRESCRIPTION.RX_MEDICINE_LIST:
            return {
                ...state,
                rxMedicineList: action.payload
            };
        case PRESCRIPTION.RX_MEDICINE_CREATE:
            return {
                ...state,
                rxMedicineList: [...state.rxMedicineList, action.payload]
            };
        case PRESCRIPTION.HEALTH_MATRIX:
            return {
                ...state,
                healthMatrix: action.payload
            };
        case PRESCRIPTION.HEALTH_MATRIX_CUSTOM:
            return {
                ...state,
                healthMatrixCustom: action.payload
            };
        case PRESCRIPTION.HEALTH_MATRIX_CUSTOM_ADD:
            return {
                ...state,
                healthMatrixCustom: [...state.healthMatrixCustom, action.payload]
            };
        case PRESCRIPTION.HEALTH_MATRIX_CLONE:
            return {
                ...state,
                healthMatrixClone: action.payload
            };
        case PRESCRIPTION.SELECTED_HEALTH_MATRIX:
            return {
                ...state,
                selectedHealthMatrix: action.payload
            };
        case PRESCRIPTION.SELECTED_HEALTH_MATRIX_IDS:
            return {
                ...state,
                selectedHealthMatrixIds: action.payload
            };
        case MEDICINE_ROUTE:
            return {
                ...state,
                medicineRoute: action.payload
            };
        case PRESCRIPTION.PRESCRIPTION_SUBSCRIPTION_PLAN:
            return {
                ...state,
                prescriptionSubscriptionPlan: action.payload
            };
        case PRESCRIPTION.DESIGNATION_LIST:
            return {
                ...state,
                designationList: action.payload
            };
        case PRESCRIPTION.SPECIALIZATION_LIST:
            return {
                ...state,
                specializationList: action.payload
            };
        default:
            return state;
    }
};

export default prescription;
