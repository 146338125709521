export const HEADER_HEIGHT = 88;
export const DRAWER_WIDTH = 260;
export const NOTE = 'NOTE';
export const MEAL_TIME_UNIT = 'MEAL_TIME_UNIT';
export const MEAL_TIME_QUANTITY = 'MEAL_TIME_QUANTITY';
export const COMMENTS = 'COMMENTS';
export const CUSTOM_DURATION = 'CUSTOM_DURATION';

export const NOTE_LIST_LABELS = {
    BEFORE_MEAL: 'before_meal',
    AFTER_MEAL: 'after_meal',
    WITH_MEAL: 'with_meal',
    BEFORE_SLEEP: 'before_sleep',
    NOT_APPLICABLE: 'not_applicable'
};

export const NOTE_LIST = [
    {
        label: 'Select one',
        value: NOTE_LIST_LABELS.NOT_APPLICABLE
    },
    {
        label: 'খাবারের পূর্বে',
        value: NOTE_LIST_LABELS.BEFORE_MEAL
    },
    {
        label: 'খাবারের পরে',
        value: NOTE_LIST_LABELS.AFTER_MEAL
    },
    {
        label: 'খাবারের সাথে',
        value: NOTE_LIST_LABELS.WITH_MEAL
    },
    {
        label: 'ঘুমানোর আগে',
        value: NOTE_LIST_LABELS.BEFORE_SLEEP
    }
];

export const getNoteLabelByValue = (value) => {
    const dosageTiming = NOTE_LIST.find((dosageTiming) => dosageTiming.value === value);
    return dosageTiming ? dosageTiming.label : dosageTiming;
};

export const CUSTOM_DURATION_OPTIONS = [
    {
        label: 'চলবে',
        value: 'continue'
    },
    {
        label: 'যখন প্রয়োজন হবে',
        value: 'যখন প্রয়োজন হবে'
    },
    {
        label: 'জ্বর ১০০ ডিগ্রির বেশি থাকলে',
        value: 'জ্বর ১০০ ডিগ্রির বেশি থাকলে'
    }
];

export const PRESCRIPTION_TYPE = {
    DIAGNOSIS: 'DIAGNOSIS', // text
    TEST_FINDINGS: 'TEST_FINDINGS', // text
    GENERAL_ADVICE: 'GENERAL_ADVICE', // text
    PLAN_OF_TREATMENT: 'PLAN_OF_TREATMENT', // text
    DIFFERENTIAL_DIAGNOSIS: 'DIFFERENTIAL_DIAGNOSIS', // text

    PATIENT_OVERVIEW: 'PATIENT_OVERVIEW',
    ON_EXAMINATION: 'ON_EXAMINATION',
    TEST_ADVICE: 'TEST_ADVICE', // multiselect
    RX_MEDICINE: 'RX_MEDICINE',
    NEXT_FOLLOW_UP: 'NEXT_FOLLOW_UP',
    SIGNATURE: 'SIGNATURE'
};

export const DOSES = {
    // OPTION 1
    OPTION_1_MORNING: 'OPTION_1_MORNING',
    OPTION_1_NOON: 'OPTION_1_NOON',
    OPTION_1_NIGHT: 'OPTION_1_NIGHT',
    // OPTION 2
    OPTION_2_INTERVAL_UNIT: 'OPTION_2_INTERVAL_UNIT',
    OPTION_2_INTERVAL: 'OPTION_2_INTERVAL',
    OPTION_2_SELECT_QUANTITY: 'OPTION_2_SELECT_QUANTITY',
    // OPTION 3
    OPTION_3_SELECT_FREQUENCY: 'OPTION_3_SELECT_FREQUENCY',
    OPTION_3_SELECT_PERIOD: 'OPTION_3_SELECT_PERIOD',
    OPTION_3_SELECT_QUANTITY: 'OPTION_3_SELECT_QUANTITY'
};

export const MEDICINE_ROUTE = 'MEDICINE_ROUTE';
export const FIRST_DOSE_TIME = 'FIRST_DOSE_TIME';
export const UNIT = 'UNIT';


export const DURATION = {
    DAY: 'DAY',
    INTERVAL: 'INTERVAL'
};

export const doseOptions = [0, 0.5, 1, 2, 3, 4, 5];
export const dailyOptions = [
    { enLabel: '1/4', enValue: 0.25 },
    { enLabel: '1/3', enValue: 0.33 },
    { enLabel: '1/2', enValue: 0.5 },
    { enLabel: '1', enValue: 1 },
    { enLabel: '2', enValue: 2 },
    { enLabel: '3', enValue: 3 },
    { enLabel: '4', enValue: 4 },
    { enLabel: '5', enValue: 5 },
    { enLabel: '6', enValue: 6 },
    { enLabel: '7', enValue: 7 }
];
// export const dailyOptions = [
//     { enLabel: '1/4', enValue: 0.25 },
//     { enLabel: '1/3', enValue: 0.33 },
//     { enLabel: '1/2', enValue: 0.5 },
//     { enLabel: '1', enValue: 1 },
//     { enLabel: '2', enValue: 2 },
//     { enLabel: '3', enValue: 3 },
//     { enLabel: '4', enValue: 4 },
//     { enLabel: '5', enValue: 5 },
//     { enLabel: '6', enValue: 6 },
//     { enLabel: '7', enValue: 7 },
//     { enLabel: '8', enValue: 8 },
//     { enLabel: '9', enValue: 9 },
//     { enLabel: '10', enValue: 10 },
//     { enLabel: '15', enValue: 15 },
//     { enLabel: '20', enValue: 20 },
//     { enLabel: '25', enValue: 25 },
//     { enLabel: '30', enValue: 30 },
//     { enLabel: '35', enValue: 35 },
//     { enLabel: '40', enValue: 40 },
//     { enLabel: '45', enValue: 45 },
//     { enLabel: '50', enValue: 50 },
//     { enLabel: '55', enValue: 55 },
//     { enLabel: '60', enValue: 60 },
//     { enLabel: '65', enValue: 65 },
//     { enLabel: '70', enValue: 70 },
//     { enLabel: '75', enValue: 75 },
//     { enLabel: '80', enValue: 80 },
//     { enLabel: '85', enValue: 85 },
//     { enLabel: '90', enValue: 90 },
//     { enLabel: '95', enValue: 95 },
//     { enLabel: '100', enValue: 100 }
// ];
export const intervalOptions = [
    { bnLabel: '১ ', enLabel: 1, enValue: 1 },
    { bnLabel: '২', enLabel: 2, enValue: 2 },
    { bnLabel: '৩', enLabel: 3, enValue: 3 },
    { bnLabel: '৪', enLabel: 4, enValue: 4 },
    { bnLabel: '৫', enLabel: 5, enValue: 5 },
    { bnLabel: '৬', enLabel: 6, enValue: 6 },
    { bnLabel: '৭', enLabel: 7, enValue: 7 },
    { bnLabel: '৮', enLabel: 8, enValue: 8 },
    { bnLabel: '৯', enLabel: 9, enValue: 9 },
    { bnLabel: '১০', enLabel: 10, enValue: 10 },
    { bnLabel: '১৫', enLabel: 15, enValue: 15 },
    { bnLabel: '২০', enLabel: 20, enValue: 20 },
    { bnLabel: '২৫', enLabel: 25, enValue: 25 },
    { bnLabel: '৩০', enLabel: 30, enValue: 30 },
    { bnLabel: '৩৫', enLabel: 35, enValue: 35 },
    { bnLabel: '৪০', enLabel: 40, enValue: 40 },
    { bnLabel: '৪৫', enLabel: 45, enValue: 45 },
    { bnLabel: '৫০', enLabel: 50, enValue: 50 },
    { bnLabel: '৫৫', enLabel: 55, enValue: 55 },
    { bnLabel: '৬০', enLabel: 60, enValue: 60 },
    { bnLabel: '৭২', enLabel: 72, enValue: 72 }
];
export const intervalQuantityOptions = [
    { enLabel: '1/4', enValue: 0.25 },
    { enLabel: '1/3', enValue: 0.33 },
    { enLabel: '1/2', enValue: 0.5 },
    { enLabel: '1', enValue: 1 },
    { enLabel: '2', enValue: 2 },
    { enLabel: '3', enValue: 3 },
    { enLabel: '4', enValue: 4 },
    { enLabel: '5', enValue: 5 },
    { enLabel: '6', enValue: 6 },
    { enLabel: '7', enValue: 7 }
];

export const frequencyQuantityOptions = [
    { enLabel: '1/4', enValue: 0.25 },
    { enLabel: '1/3', enValue: 0.33 },
    { enLabel: '1/2', enValue: 0.5 },
    { enLabel: '1', enValue: 1 },
    { enLabel: '2', enValue: 2 },
    { enLabel: '3', enValue: 3 },
    { enLabel: '4', enValue: 4 },
    { enLabel: '5', enValue: 5 },
    { enLabel: '6', enValue: 6 },
    { enLabel: '7', enValue: 7 }
];

export const durationOptions = [
    { bnLabel: '১ ', enLabel: 1, enValue: 1 },
    { bnLabel: '২', enLabel: 2, enValue: 2 },
    { bnLabel: '৩', enLabel: 3, enValue: 3 },
    { bnLabel: '৪', enLabel: 4, enValue: 4 },
    { bnLabel: '৫', enLabel: 5, enValue: 5 },
    { bnLabel: '৬', enLabel: 6, enValue: 6 },
    { bnLabel: '৭', enLabel: 7, enValue: 7 },
    { bnLabel: '৮', enLabel: 8, enValue: 8 },
    { bnLabel: '৯', enLabel: 9, enValue: 9 },
    { bnLabel: '১০', enLabel: 10, enValue: 10 },
    { bnLabel: '১৫', enLabel: 15, enValue: 15 },
    { bnLabel: '২০', enLabel: 20, enValue: 20 },
    { bnLabel: '২৫', enLabel: 25, enValue: 25 },
    { bnLabel: '৩০', enLabel: 30, enValue: 30 },
    { bnLabel: '৩৫', enLabel: 35, enValue: 35 },
    { bnLabel: '৪০', enLabel: 40, enValue: 40 },
    { bnLabel: '৪৫', enLabel: 45, enValue: 45 }
];

export const mealTimeQuantityOptions = [1, 2, 3, 4, 5, 6, 7];
export const mealTimeUnitOptions = [
    { name: 'Hour', value: 'hour' },
    { name: 'Minute', value: 'minute' }
];
export const unitIntervalOptions = [
    { name: 'Hourly', value: 'hour' },
    { name: 'Minute', value: 'minute' }
];

export const unitCalenderIntervalOptions = [
    { name: 'Day', enLabel: 'Day', value: 'day', bnLabel: 'দিন', enValue: 'day' },
    { name: 'Week', enLabel: 'Week', value: 'week', bnLabel: 'সপ্তাহ', enValue: 'week' },
    { name: 'Month', enLabel: 'Month', value: 'month', bnLabel: 'মাস', enValue: 'month' },
    { name: 'Year', enLabel: 'Year', value: 'year', bnLabel: 'বছর', enValue: 'year' }
];

export const selectFrequencyOptions = [
    {
        value: '1',
        bnLabel: 'একবার ',
        label: 'Once'
    },
    {
        value: '2',
        bnLabel: 'দুইবার ',
        label: 'Twice'
    },
    {
        value: '3',
        bnLabel: 'তিনবার ',
        label: 'Thrice'
    },
    {
        value: '4',
        bnLabel: 'চারবার ',
        label: 'Four times'
    },
    {
        value: '5',
        bnLabel: 'পাঁচবার ',
        label: 'Five times'
    },
    {
        value: '6',
        bnLabel: 'ছয়বার ',
        label: 'Six times'
    }
];

export const selectPeriodOptions = [
    {
        value: '1',
        bnLabel: 'প্রতিদিন ',
        label: 'Daily'
    },
    {
        value: '2',
        bnLabel: 'প্রতিসপ্তাহে ',
        label: 'Weekly'
    },
    {
        value: '3',
        bnLabel: 'প্রতি দুই সপ্তাহে ',
        label: 'Bi-weekly'
    },
    {
        value: '4',
        bnLabel: 'প্রতি তিন সপ্তাহে ',
        label: 'Tri-weekly'
    },
    {
        value: '5',
        bnLabel: 'প্রতি মাসে ',
        label: 'Monthly'
    },
    {
        value: '6',
        bnLabel: 'প্রতি দুই মাসে ',
        label: 'Bi-monthly'
    },
    {
        value: '7',
        bnLabel: 'প্রতি তিন মাসে ',
        label: 'Quarterly'
    },
    {
        value: '8',
        bnLabel: 'প্রতি ছয় মাসে ',
        label: 'Half-yearly'
    },
    {
        value: '9',
        bnLabel: 'প্রতি বছর ',
        label: 'Yearly'
    }
];
export const selectQuantityOptions = [0.5, 1, 2, 3, 4, 5];
export const hourlyPeriodOptions = [1, 2, 3, 4, 6, 8, 12, 24, 48, 72];
export const nextFollowUpOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
export const unitOptions = ['চামচ', 'মি.লি', 'টা', 'ড্রপ', 'পাফ', 'বার'];

export const notePayload = (val) => {
    if (NOTE_LIST_LABELS.BEFORE_MEAL === val) {
        return true;
    } else if (NOTE_LIST_LABELS.AFTER_MEAL === val) {
        return false;
    } else if (NOTE_LIST_LABELS.NOT_APPLICABLE === val) {
        return null;
    }
};

export const notePayloadBool = (bool) => {
    if (bool === true) {
        return NOTE_LIST_LABELS.BEFORE_MEAL;
    } else if (bool === false) {
        return NOTE_LIST_LABELS.AFTER_MEAL;
    } else if (bool == null) {
        return NOTE_LIST_LABELS.NOT_APPLICABLE;
    }
};

export const checkCustomMedicine = (item) => {
    if (item.medicine == null) {
        return {
            id: null,
            manufacturer_name: null,
            brand_name: item.brand_name,
            generic_name: null,
            strength: item.strength,
            dosage_type: item.dosage_type
        };
    }
    return item.medicine;
};
