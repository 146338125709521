export const PRESCRIPTION = {
    CALENDAR: 'CALENDAR',
    PATIENT_DISEASE: 'PATIENT_DISEASE',
    CHAMBER: 'CHAMBER',
    GET_PROFILE: 'GET_PROFILE',
    CREATE: 'CREATE',
    PATIENT_DETAILS: 'PATIENT_DETAILS',
    IS_REGISTERED_PATIENT: 'IS_REGISTERED_PATIENT',
    ADD_PATIENT: 'ADD_PATIENT',
    TEST_ADVICE_LIST: 'TEST_ADVICE_LIST',
    TEST_ADVICE_CUSTOM_TEXT: 'TEST_ADVICE_CUSTOM_TEXT',
    TEST_ADVICE_SELECTED_LIST: 'TEST_ADVICE_SELECTED_LIST',
    REFER_DIAGNOSTIC_CENTER: 'REFER_DIAGNOSTIC_CENTER',
    NEXT_FOLLOW_UP: 'NEXT_FOLLOW_UP',
    MEDICINE_LIST: 'MEDICINE_LIST',
    RX_MEDICINE_LIST: 'RX_MEDICINE_LIST',
    RX_MEDICINE_CREATE: 'RX_MEDICINE_CREATE',
    HEALTH_MATRIX: 'HEALTH_MATRIX',
    HEALTH_MATRIX_CUSTOM: 'HEALTH_MATRIX_CUSTOM',
    HEALTH_MATRIX_CUSTOM_ADD: 'HEALTH_MATRIX_CUSTOM_ADD',
    HEALTH_MATRIX_CLONE: 'HEALTH_MATRIX_CLONE',
    SELECTED_HEALTH_MATRIX: 'SELECTED_HEALTH_MATRIX',
    SELECTED_HEALTH_MATRIX_IDS: 'SELECTED_HEALTH_MATRIX_IDS',
    PRESCRIPTION_SUBSCRIPTION_PLAN: 'PRESCRIPTION_SUBSCRIPTION_PLAN',
    DESIGNATION_LIST: 'DESIGNATION_LIST',
    SPECIALIZATION_LIST: 'SPECIALIZATION_LIST'
};
