import { AUTH } from './actionType';

const initialState = {
    currentUser: {
        token: '',
        isLoggedIn: false,
        userDetails: {}
    }
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH.LOGIN:
            return {
                ...state,
                currentUser: action.payload
            };
        case AUTH.UPDATE_SIGNATURE:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    userDetails: {
                        ...state.currentUser.userDetails,
                        doctor_profile: {
                            ...state.currentUser.userDetails.doctor_profile,
                            signature: {
                                ...state.currentUser.userDetails.doctor_profile.signature,
                                url: action.payload
                            }
                        }
                    }
                }
            };
        case AUTH.UPDATE_CURRENT_USER:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    userDetails: action.payload
                }
            };

        default:
            return state;
    }
};

export default auth;
