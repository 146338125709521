import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Auth = React.lazy(() => import('./modules/Auth'));
const Application = React.lazy(() => import('./modules/Application'));
const Logout = React.lazy(() => import('./modules/Application/Profile/logout'));

function App() {
    const { currentUser } = useSelector((state) => state.auth);
    console.warn = console.error = () => {};
    return (
        <Suspense fallback={<LinearProgress />}>
            <SnackbarProvider
                action={(snackbarId) => (
                    <IconButton onClick={() => closeSnackbar(snackbarId)}>
                        <Close />
                    </IconButton>
                )}
            />
            <Router>
                <Routes>
                    <Route path='/*' element={currentUser && currentUser.isLoggedIn ? <Application /> : <Auth />} />
                    <Route path='/logout' element={<Logout />} />
                </Routes>
            </Router>
        </Suspense>
    );
}

export default App;
