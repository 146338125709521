import { DISEASE } from './actionType';

const initialState = {
    diseases: [],
    tempDiseases: [],
    selectedDiseaseIds: [],
    selectedDiseases: [],
    selectedCustomDisease: ''
};

const disease = (state = initialState, action) => {
    switch (action.type) {
        case DISEASE.LIST:
            return {
                ...state,
                diseases: action.payload
            };
        case DISEASE.TEMP_LIST:
            return {
                ...state,
                tempDiseases: action.payload
            };
        case DISEASE.SELECTED_CUSTOM_DISEASE:
            return {
                ...state,
                selectedCustomDisease: action.payload
            };
        case DISEASE.SELECTED_DISEASE_IDS:
            return {
                ...state,
                selectedDiseaseIds: action.payload
            };

        default:
            return state;
    }
};

export default disease;
